.dashboard-nav-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: safe;
    justify-content: space-between;
}

.dashboard-item {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    display: flex;
    margin: 0 6px;
}
