@import '../../../../scss/bootstrap-variables';

h2 {
    color: map-get($theme-colors, dark);
}

.header {
    align-content: center;
    justify-content: left;
    display: flex;
}
