@import '../../scss/bootstrap-variables';

.panel {
    background-color: #3c4b64;
    width: 100%;
    padding-top: 10px;
    margin-top: -1px;
    min-height: 100vh;
    height: 100%;
}

.singleEvent {
    margin: 1px 0;
    color: rgba(255, 255, 255, 0.8);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.padding {
    padding: 12px 24px;
}

.title {
    @extend .padding;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &:active,
    &:hover {
        background: $green;
        color: $white;
    }
}

.link {
    @extend .padding;
    cursor: pointer;
    color: $green;
    text-align: right;
}

.info {
    @extend .padding;
    padding-top: 0;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.4);
}

.close {
    color: $white;
    text-align: right;
    padding: 10px 18px;
    margin-bottom: 10px;

    svg {
        cursor: pointer;
    }
}

.json {
    @extend .padding;

    pre {
        color: rgba(255, 255, 255, 0.6);
    }
}

@mixin borderClass($var) {
    border-left: 4px solid $var;
}

.blue {
    @include borderClass($blue);
}

.green {
    @include borderClass($green);
}

.indigo {
    @include borderClass($indigo);
}

.purple {
    @include borderClass($purple);
}

.pink {
    @include borderClass($pink);
}

.orange {
    @include borderClass($orange);
}

.yellow {
    @include borderClass($yellow);
}

.teal {
    @include borderClass($teal);
}

.cyan {
    @include borderClass($cyan);
}
