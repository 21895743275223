@import '../../../../scss/bootstrap-variables';

.tile {
    width: 100%;
    padding: 20px;
    color: $white;
    position: relative;
    background-color: $green;
    font-weight: 600;
    margin: 2px 0;

    &:hover {
        background-color: $dark-green;
    }

    .title {
        margin-top: 0;
        color: $white;
        font-weight: 600;
    }
}

.icon-wrapper {
    font-size: 104px;
    opacity: 0.15;
    text-align: right;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
