@import '../../scss/bootstrap-variables';

.Accordion {
    border-bottom: 1px solid $green;
    padding-bottom: 10px;
    color: $green;
    font-size: 20px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding-right: 20px;

    &__children {
        margin-bottom: 30px;
    }
}
