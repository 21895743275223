.text-green {
    color: $green;
}

.text-italic {
    font-style: italic;
}

.text-muted {
    color: #28272a;
}

.cursor-pointer {
    cursor: pointer;
}

@for $i from 4 through 61 {
    .mt-#{$i} {
        margin-top: #{$i}px !important;
    }
    .mb-#{$i} {
        margin-bottom: #{$i}px !important;
    }
    .ml-#{$i} {
        margin-left: #{$i}px !important;
    }
    .mr-#{$i} {
        margin-right: #{$i}px !important;
    }
}

@for $i from 4 through 61 {
    .p-#{$i} {
        padding: #{$i}px !important;
    }
    .pt-#{$i} {
        padding-top: #{$i}px !important;
    }
    .pb-#{$i} {
        padding-bottom: #{$i}px !important;
    }
    .pl-#{$i} {
        padding-left: #{$i}px !important;
    }
    .pr-#{$i} {
        padding-right: #{$i}px !important;
    }
}

.no-text-underline {
    text-decoration: none !important;
}

.no-padding {
    padding: 0 !important;
}

.no-padding-right {
    padding-right: 0 !important;
}
