@import '../../../../scss/bootstrap-variables';

.back-button {
    $size: 36px;
    width: $size;
    height: $size;
    border-radius: 50% !important;

    i {
        $i-size: 18px;
        color: $white;
        font-size: $i-size;
        line-height: $i-size + 4;
        padding: 0;
        margin: 0;
    }
}
