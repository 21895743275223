@import '../../scss/bootstrap-variables';

#nprogress .bar {
    background-color: map-get($theme-colors, primary);
    height: 5px;
}

#nprogress .spinner {
    top: 25px;
    right: 25px;
}

#nprogress .spinner-icon {
    width: 30px;
    height: 30px;
    border: 4px solid transparent;
    background-color: transparent;
    border-top-color: map-get($theme-colors, primary);
    border-left-color: map-get($theme-colors, success);
}
