.wrapper {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.item {
    flex: 1 1 33.33%;
    flex-grow: 0;

    .card {
        height: 100%;
    }
}
