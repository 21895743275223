@import '../../../../scss/bootstrap-variables';

.logs {
    background: $gray-800;
    margin-top: 40px;
    padding: 20px;
    font-family: 'Courier New', Courier, monospace;
    font-size: 16px;
}

.log {
    padding: 12px 0;
}

.date {
    margin-bottom: 3px;
    font-weight: 700;
}

.error {
    color: $red;
}

.warning {
    color: $yellow;
}

.success {
    color: $green;
}

.info {
    color: $white;
}

.btn {
}
