@import '../../../../../../scss/bootstrap-variables';

.selected {
    border: 2px solid $green;
    border-radius: 2px;
    box-shadow: 0 0 14px lighten($green, 20);
}

.inactive {
    background: $gray-200;
    color: $gray-600;
}

.crawlerTemplate {
    &:hover {
        box-shadow: 0 0 14px lighten($gray-800, 20);
    }
}
