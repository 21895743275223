@import '../../../../../scss/bootstrap-variables';

.synonymInput {
    display: flex;
    margin-bottom: 6px;
}

.removeBtn {
    background: none;
    border: none;
    color: $red;
    border: 1px solid $red;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;

    &:hover,
    &:active {
        background: $red;
        color: $white;
        border-color: $red;
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
    }
}

.addBtnWrapper {
    margin-top: 40px;
    text-align: right;
}

.header {
    margin-top: 20px;
}
