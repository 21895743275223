// Here you can add other styles
$btn-radius: 4px;
$margin: 40px;

.flex-center {
    align-content: center;
    justify-content: center;
    display: flex !important;
}

.switch-success > .switch-input:checked ~ .switch-label {
    background: $green !important;
    border-color: darken($color: $green, $amount: 10);
}

.section {
    margin-top: $margin;
    margin-bottom: $margin;
}

.section-top {
    margin-top: $margin;
}

.section-bottom {
    margin-bottom: $margin;
}

.c-sidebar-close {
    z-index: 999;
}

.c-sidebar-nav {
    padding-top: 60px !important;
}

.c-sidebar-nav-item-active {
    .c-sidebar-nav-link,
    .c-sidebar-nav-icon {
        color: $white !important;
    }

    background: $green;
}

.btn {
    border-radius: $btn-radius !important;
}

.breadcrumb {
    border: none !important;
    padding: 0 !important;
}

.c-sidebar-nav-dropdown-items {
    max-height: none !important;
    padding-left: 20px !important;
    z-index: 999;
}

.nav-dropdown-toggle {
    cursor: pointer;
}

.sidebar-menu-dropdown-icon {
    margin-left: auto;
}

.form-check-input {
    margin-left: 0 !important;
}

.card {
    box-shadow: 3px 3px 3px 0px rgb(189, 189, 189);
    border: none !important;

    .card-footer {
        border: none !important;
    }

    .card-header {
        border: none !important;
    }
}

.form-group {
    align-items: center;
}

label {
    margin-bottom: 0 !important;
}

.align-center {
    display: flex !important;
    align-items: center !important;
}

.text-gray {
    color: $gray-400;
}
